@import '../../../../scss/theme-bootstrap';

.product-brief-shades {
  padding: 14px 0 7px;
  margin: 0;
  list-style: none;
  @include breakpoint($medium-up) {
    padding: 0;
  }
  .shade-inventory-status--4,
  .shade-inventory-status--7 {
    &:after {
      content: ' ';
      position: relative;
      #{$rdirection}: 20px;
      top: -20px;
      border-#{$rdirection}: 1px solid $color-core-black;
      transform: rotate(45deg);
      display: block;
      width: 100%;
      height: 100%;
      #{$ldirection}: unset;
      border-#{$ldirection}: 0;
      @include breakpoint($medium-up) {
        #{$rdirection}: 5px;
        top: -5px;
      }
    }
  }
  .product-brief-shades__grid--layout {
    .shade-inventory-status--4,
    .shade-inventory-status--7 {
      &:after {
        #{$rdirection}: 6px;
        top: -6px;
        width: 100%;
        height: 100%;
        #{$ldirection}: unset;
        border-#{$ldirection}: 0;
      }
    }
    &.product-brief-shades__grid {
      @include breakpoint($medium-up) {
        padding: 0;
      }
    }
  }
  .product-brief-shades__no-carousel {
    .slick-track {
      margin: 0;
    }
  }
  &__header {
    width: 100%;
    display: block;
    margin: 0;
    padding: 0 20px;
    min-height: 20px;
    @include breakpoint($medium-up) {
      padding: 0;
    }
  }
  &__title {
    text-align: #{$ldirection};
    display: block;
    position: relative;
    max-width: 345px;
    margin-bottom: 5px;
    @include breakpoint($medium-up) {
      max-width: 100%;
    }
    .tabbed-products-block & {
      max-width: 330px;
      @include breakpoint($medium-up) {
        max-width: 100%;
      }
    }
  }
  &__name {
    @include typography-link-text();
    display: inline-block;
    clear: none;
    color: $color-core-black;
    text-transform: none;
    margin: 0;
  }
  &__badge {
    @include typography-link-text();
    text-transform: none;
    display: inline-block;
    clear: none;
    color: $color-core-black;
    position: relative;
    margin: 0;
  }
  &__description {
    @include typography-link-text();
    min-height: 60px;
    color: $color-gray-op65;
    display: block;
    @include breakpoint($medium-up) {
      display: none;
    }
  }
  &__grid {
    margin: 0 auto;
    padding: 30px 0;
    max-width: none;
    overflow-x: visible;
    @include breakpoint($medium-up) {
      height: auto;
      max-height: 25px;
      padding: 0;
    }
    &-inner {
      width: 100%;
    }
    &--layout {
      max-width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: start;
      max-height: none;
      overflow: hidden;
      padding-top: 2px;
      .product-brief-shades[aria-expended='true'] & {
        max-height: 100%;
      }
      .product-brief-shades__shade-item {
        min-width: auto;
        text-align: center;
        margin: 3px;
        padding: 0;
        .product-brief-shades__shade {
          margin: 0 auto;
          width: 20px;
          height: 20px;
        }
      }
    }
    &.product-brief-shades__no-carousel {
      .mpp-container .product-brief__shades & {
        padding: 0;
      }
    }
  }
  &__shade {
    width: 33px;
    height: 33px;
    border-radius: 50%;
    transition: all 0.2s ease-in-out;
    display: block;
    border: 4px solid $color-white;
    box-shadow: $color-white 0 0 1px 1px;
    @include breakpoint($medium-up) {
      width: 19px;
      height: 19px;
      margin: auto;
      border: 1px solid $color-white;
    }
    &.selected,
    &:hover {
      border: 4px solid $color-white;
      box-shadow: $color-core-black 0 0 1px 1px;
      @include breakpoint($medium-up) {
        border: 1px solid $color-white;
      }
    }
    .product-brief-shades__no-carousel & {
      @include breakpoint($medium-up) {
        margin: 0;
      }
    }
  }
  &__shade-item {
    margin-#{$rdirection}: 17px;
    padding-#{$ldirection}: 3px;
    float: #{$ldirection};
    overflow: visible;
    display: block;
    height: auto;
    @include breakpoint($medium-up) {
      margin: 1.5px;
      padding: 1.5px;
    }
  }
  .slick-list {
    padding: 0 10%;
    @include breakpoint($medium-up) {
      padding: 0;
      margin-top: 8px;
    }
  }
  .slick-track {
    padding: 3px 0;
    margin: 0 auto;
    @include breakpoint($medium-up) {
      padding: 0;
    }
  }
  .slick-prev {
    min-width: 12px;
    min-height: 12px;
    #{$ldirection}: -24px;
    top: 15px;
    position: absolute;
    @include breakpoint($medium-up) {
      #{$ldirection}: -10px;
      top: 6px;
    }
  }
  .slick-next {
    min-width: 12px;
    min-height: 12px;
    #{$rdirection}: -26px;
    top: 15px;
    position: absolute;
    &:before {
      #{$rdirection}: auto;
      position: relative;
      height: auto;
    }
    @include breakpoint($medium-up) {
      #{$rdirection}: -7px;
      top: 6px;
    }
  }
  .slick-next,
  .slick-next:before,
  .slick-prev,
  .slick-prev:before {
    font-size: 20px;
    line-height: 1.5;
    color: $color-core-black;
  }
  &__personalization {
    height: 22px;
    display: none;
    text-align: center;
    width: auto;
    overflow: auto;
    &.active {
      display: block;
      height: 22px;
      width: 100%;
    }
  }
  &__hex {
    display: none;
  }
}
